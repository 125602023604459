import { useCallback, useMemo, useState } from 'react';
import { setDefaultLocale } from 'react-datepicker';
import { differenceInMinutes } from 'date-fns';
import { enGB, fr, de, nl } from 'date-fns/locale';
import { DEFAULT_LANGUAGE, USER_LANGUAGE_CODE } from 'utils/constants';
import { fetchTranslations, getTranslations } from './util';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { generateIntl } from 'locales/intl';

export default function useTranslation() {
  const [currentLanguage, setLanguage] = useLocalStorage(
    USER_LANGUAGE_CODE,
    DEFAULT_LANGUAGE,
  );
  const [messages, setMessages] = useState(() => getTranslations().messages);

  const changeLanguage = useCallback(
    async (language: string) => {
      const translations = getTranslations();
      const minutesSinceLastUpdate = differenceInMinutes(
        Date.now(),
        translations.updatedAt || 0,
      );

      if (minutesSinceLastUpdate > 120 || language !== translations.lang) {
        const data = await fetchTranslations(language);
        setMessages(data.messages);
      } else {
        setMessages(translations.messages);
      }

      setLanguage(language);

      setDateFnsLocale(language);
    },
    [setLanguage],
  );

  const setDateFnsLocale = (language: string) => {
    const locales = { en: enGB, fr, de, nl };
    setDefaultLocale(locales[language]);
  };

  const intl = useMemo(
    () =>
      generateIntl({
        locale: currentLanguage,
        defaultLocale: DEFAULT_LANGUAGE,
        messages: messages,
      }),
    [currentLanguage, messages],
  );

  return {
    intl,
    changeLanguage,
  };
}
