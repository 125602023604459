export const API_V1 = 'v1';
export const API_V2 = 'v2';
export const API_V3 = 'v3';
export const API_V4 = 'v4';
export const API_V5 = 'v5';

/* Asset Management API */
export const CUSTOMERS = 'customers';
export const CUSTOMERS_INFO = 'customers/%id';
export const CUSTOMERS_LABELS = 'customers/%id/labels';
export const CUSTOMERS_LOCATIONS = 'customers/%id/locations';
export const CUSTOMERS_SERVICES = 'customers/%id/services';
export const CUSTOMERS_CONTRACT_PHASES = 'customers/contractPhases';
export const HIERARCHY_TREE = 'hierarchy/%id/tree';
export const PARENT_TREE = 'hierarchy/%id/parenttree';
export const HIERARCHY_ASSETS = 'hierarchy/assets/%id';
export const HIERARCHY_LABELS = 'hierarchy/labels/%id';
export const HIERARCHY_SPACE_DETAILS = 'hierarchy/spaces/%id';
export const HIERARCHY_SPACES_DETAILS = 'hierarchy/spaces';
export const HIERARCHY_SEQUENCE = 'hierarchy/sequence';
export const HIERARCHY_MAINTAINABLE_ITEMS_FROM_LOCATION =
  'hierarchy/locations/%id/maintainableitems';
export const HIERARCHY_MAINTAINABLE_ITEMS = 'hierarchy/maintainableitems';
export const HIERARCHY_MAINTAINABLE_ITEM_DETAILS =
  'hierarchy/maintainableitems/%id';
export const HIERARCHY_MAINTAINABLE_ITEMS_TYPE =
  'hierarchy/maintainableitems/types';
export const HIERARCHY_MAINTAINABLE_ITEMS_METADATA =
  'hierarchy/maintainableitems/metadata';
export const HIERARCHY_ASSET_DETAILS = 'hierarchy/assets/%id';
export const HIERARCHY_ASSET_SUB_UNITS = 'hierarchy/assets/%id/subunits';
export const HIERARCHY_ASSETS_FROM_SPACE = 'hierarchy/spaces/%id/assets';
export const HIERARCHY_LOCATION_ASSETS = 'hierarchy/locations/%id/assets';
export const HIERARCHY_LOCATION_DETAILS = 'hierarchy/locations/%id';
export const HIERARCHY_LOCATION_CHECKOUT = 'hierarchy/locations/%id/checkout';
export const HIERARCHY_LOCATION_CHECKIN = 'hierarchy/locations/%id/checkin';
export const HIERARCHY_LOCATION_COUNTRIES = 'hierarchy/locations/countries';
export const HIERARCHY_LOCATION_OFFLINE_CHANGES =
  'hierarchy/locations/%id/offline/changes';
export const HIERARCHY_LOCATION_OFFLINE_CHECKIN =
  'hierarchy/locations/%id/offline/checkin';
export const HIERARCHY_LOCATION_OFFLINE_CHECKOUT =
  'hierarchy/locations/%id/offline/checkout';
export const HIERARCHY_LOCATION_OFFLINE_CLEAR =
  'hierarchy/locations/%id/offline/clear';
export const HIERARCHY_LOCATION_OFFLINE_STATUS =
  'hierarchy/locations/%id/offline/status';
export const HIERARCHY_SUB_UNIT_DETAILS = 'hierarchy/subunits/%id';
export const HIERARCHY_SUB_UNIT_TYPES = 'hierarchy/subunits/types';
export const HIERARCHY_SUB_UNIT_METADATA = 'hierarchy/subunits/metadata';
export const HIERARCHY_SUB_UNIT_MAINTAINABLE_ITEMS =
  'hierarchy/subunits/%id/maintainableitems';
export const HIERARCHY_SUB_UNIT_MAINTAINABLE_ITEMS_ATTRIBUTES =
  'hierarchy/subunits/%id/maintainableitems/attributes';
export const HIERARCHY_CUSTOMERS_TAGS_EQUIPMENT =
  'hierarchy/customers/%customerId/tags/equipment';
export const HIERARCHY_CUSTOMERS_CERTIFICATE_SUMMARY =
  'hierarchy/customers/%customerId/equipment/certificate/summary';
export const HIERARCHY_CUSTOMERS_CERTIFICATE_CALENDAR =
  'hierarchy/customers/%customerId/equipment/certificate/calendar/summary';
export const HIERARCHY_TAGS = 'hierarchy/tags/%tagId';
export const HIERARCHY_TAGS_SEARCH = 'hierarchy/tags/search';
export const HIERARCHY_TAGS_LINK = 'hierarchy/tags/%tagId/equipment';
export const HIERARCHY_EQUIPMENT_TYPES =
  'hierarchy/locations/%id/equipments/aggregated';
export const LOCATION_EQUIPMENTS =
  'hierarchy/locations/%locationId/miTypes/%miTypeId/equipments';

/* File Management API */
export const ENTITY_RESOURCES = 'entities/%id/resources';
export const ENTITY_RESOURCE = 'entities/%id/resources/%resourceId';
export const ENTITY_RESOURCE_TYPES = 'entitytypes/%id/resourcetypes';
export const ENTITY_RESOURCE_FIELDS = 'entitytypes/resourcetypes/fields';
export const LOCATION_RESOURCES = 'locations/%id/resources';
export const ALL_ENTITY_RESOURCES = 'entity/resources';
export const UPLOAD_TEMPLATE = 'templates/upload/excel';
export const HIERARCHY_DOWNLOAD_TEMPLATE = 'templates/download/hierarchy';
export const HIERARCHY_DOWNLOAD_TEMPLATE_WITH_VALUES =
  'templates/download/withvalues';
export const HIERARCHY_DOWNLOAD_TEMPLATE_HISTORY =
  'templates/download/maintainableitems/history';
export const HIERARCHY_DOWNLOAD_CERTIFICATES = 'export/entities/resources';
export const HIERARCHY_DOWNLOAD_TAGS = 'templates/download/equipments/tags';
export const HIERARCHY_DOWNLOAD_CERTIFICATE = 'certificates/download';

/* Condition Monitoring API */
export const CONDITION_MONITORING_GROUPED_SEVERITY_BY_LOCATION =
  'conditionmonitoring/locations/%id/assets/severity';
export const CONDITION_MONITORING_REPORT =
  'conditionmonitoring/locations/%locationId/assets/severityReport';
export const CONDITION_MONITORING_HIERARCHY_TREE =
  'conditionmonitoring/locations/%id/assets/tree/severity';
export const CONDITION_MONITORING_LOCATION_ASSETS_CONDITION_SCORES =
  'conditionmonitoring/locations/%id/assets/conditionscores/aggregated';
export const CONDITION_MONITORING_ASSET_AGGREGATED_SENSOR_ALERT =
  'conditionmonitoring/assets/%id/sensordata/alerts/aggregated';
export const CONDITION_MONITORING_ASSET_AGGREGATED_CONDITION_SCORE =
  'conditionmonitoring/assets/%id/conditionscore/aggregated';
export const CONDITION_MONITORING_ASSET_CONDITION_SCORE_SENSOR_HISTORY =
  'conditionmonitoring/assets/%id/conditionscores/sensordata/history';
export const CONDITION_MONITORING_ASSET_CONDITION_SCORE_SKF =
  'conditionmonitoring/assets/%id/conditionscores/skf/history';
export const CONDITION_MONITORING_SUB_UNIT_SENSOR_ALERTS =
  'conditionmonitoring/subunits/%id/sensordata/alerts';
export const CONDITION_MONITORING_SUB_UNIT_AGGREGATED_CONDITION_SCORE =
  'conditionmonitoring/subunits/%id/conditionscore/aggregated';
export const CONDITION_MONITORING_MAINTAINABLE_ITEM_SENSOR_ALERTS =
  'conditionmonitoring/maintainableitems/%id/sensordata/alerts';
export const CONDITION_MONITORING_MAINTAINABLE_ITEM_AGGREGATED_SENSOR_ALERT =
  'conditionmonitoring/maintainableitems/%id/sensordata/alerts/aggregated';
export const CONDITION_MONITORING_MAINTAINABLE_ITEM_CONDITION_SCORES =
  'conditionmonitoring/maintainableitems/%id/conditionscores';
export const CONDITION_MONITORING_MAINTAINABLE_ITEM_AGGREGATED_CONDITION_SCORE =
  'conditionmonitoring/maintainableitems/%id/conditionscore/aggregated';
export const CONDITION_MONITORING_MAINTAINABLE_ITEM_CONDITION_SCORE_SKF =
  'conditionmonitoring/maintainableitems/%id/conditionscores/skf/history';
export const CONDITION_MONITORING_MAINTAINABLE_ITEM_SENSOR_HISTORY =
  'conditionmonitoring/maintainableitems/%id/conditionscores/sensordata/history';
export const CONDITION_MONITORING_MAINTAINABLE_ITEM_LATEST_SENSOR =
  'conditionmonitoring/maintainableitems/%id/sensors/conditionscores/sensordata/latest';
export const CONDITION_MONITORING_MAINTAINABLE_ITEM_MANUAL_SURVEY =
  'conditionmonitoring/maintainableitems/%id/surveys/MANUAL';
export const CONDITION_MONITORING_SURVEYS = 'conditionmonitoring/surveys';
export const CONDITION_MONITORING_POST_SURVEY_TO_LOCATION =
  'conditionmonitoring/surveys/%surveyType/import/%customerId/%locationId';
export const CONDITION_MONITORING_SENSOR_CONDITION_SCORE_SENSOR_HISTORY =
  'conditionmonitoring/sensors/%id/conditionscores/sensordata/history';
export const CONDITION_MONITORING_SENSOR_PROPERTIES_LATEST_SENSOR_SCORE =
  'conditionmonitoring/sensors/%sensorId/properties/conditionscores/sensordata/latest';
export const CONDITION_MONITORING_PACK_CONDITION_SCORE =
  'conditionmonitoring/packs/%id/conditionscores/packdata/latest';
export const CONDITION_MONITORING_PACK_CONDITION_SCORE_HISTORY =
  'conditionmonitoring/packs/%id/conditionscores/packdata/history';
export const CONDITION_MONITORING_PROPERTY_ALERT =
  'conditionmonitoring/properties/%propertyId/alerts/latest';

/* User Management API */
export const USER_MGMT_PROFILE_ME = 'me';
export const USER_MGMT_CUMGMT_USERS = 'customers/%id/users';
export const USER_MGMT_LOCATIONS_USERS = 'locations/%id/users';
export const USER_MGMT_USER_ROLES = 'roles';
export const USER_MGMT_LANGUAGES = 'languages';
export const USER_MGMT_USER_ALL_USERS = 'users';
export const USER_MGMT_USER_USER = 'users/%id';
export const USER_MGMT_CONTACTS_VERIFICATION =
  'users/%id/contacts/verification';
export const USER_MGMT_CONTACTS_VERIFICATION_CODE =
  'users/%id/contacts/verification/%otp';
export const USER_NOTIFICATION_TYPES = 'notifications';
export const USER_CUSTOMER_NOTIFICATION_HIERARCHY_PREFERENCES =
  'customers/%customerId/users/%userId/notifications/%notificationId/hierarchypreferences';
export const USER_NOTIFICATION_HIERARCHY_PREFERENCES =
  'users/%userId/notifications/%notificationId/hierarchypreferences';

/* Task Management API */
export const LOCATION_TASKS = 'taskmanagement/locations/%id/tasks';
export const SUB_UNIT_TASKS = 'taskmanagement/subunits/%id/tasks';
export const MAINTAINABLE_ITEM_TASKS =
  'taskmanagement/maintainableitems/%id/tasks';
export const TASK = 'taskmanagement/tasks/%id';
export const TASKS = 'taskmanagement/tasks';
export const TASK_SUBSCRIBE = 'taskmanagement/tasks/%id/subscription';
export const DEACTIVATE_TASK = 'taskmanagement/tasks/%id/deactivate';
export const WORKLOGS = 'taskmanagement/tasks/%id/worklogs';
export const TASK_REPORT = 'taskmanagement/locations/%id/tasks/report';
export const ASSIGN_TASKS = 'taskmanagement/tasks/bulkassignee';
export const TASK_TYPE_METADATA = 'taskmanagement/tasks/type/metadata';

/* Sensor API */
export const LOCATION_ACTIVE_SENSORS = 'locations/%id/sensors/active';
export const ASSET_ACTIVE_SENSORS = 'assets/%id/sensors/active';
export const MAINTAINABLE_ITEM_ACTIVE_SENSORS =
  'maintainableitems/%id/sensors/active';
export const PROPERTIES_METADATA = 'properties/metadata';
export const SENSOR_VALUES = 'sensors/%id/values';
export const SENSOR_PROPERTIES = 'providers/sensors/%sensorId/properties';
export const SENSORS_LOCATION_UNLINKED =
  'locations/%id/sensors/types/%type/devices';
export const PROVIDERS_LOCATION_UNLINKED =
  'locations/%id/providers/%type/devices';
export const SENSORS_PROJECTS_LINKED_TO_LOCATION =
  'locations/%id/sensors/types/%type/projects/';
// For now only works for Netbiter. This should replace previous one
export const PROVIDERS_PROJECTS_LINKED_TO_LOCATION =
  'locations/%id/providers/%type/projects/';
export const SENSORS_PROJECT_UNLINKED_FROM_LOCATION =
  'locations/%id/sensors/types/%type/projects/%projectId';
export const PROVIDERS_PROJECT_UNLINKED_FROM_LOCATION =
  'locations/%id/providers/%type/projects/%projectId';
export const SENSORS_DEACTIVATE_DEVICE = 'sensors/%id/deactivate';
export const SENSORS_PROJECTS_UNLINKED =
  'sensors/types/%type/projects/unlinked';
// For now only works for Netbiter. This should replace previous one
export const PROVIDERS_PROJECTS_UNLINKED = 'providers/%type/projects/unlinked';
export const SENSORS_ACTIVATE_DEVICE =
  'maintainableitems/%id/sensors/types/%type/devices';
export const PROVIDERS_ACTIVATE_DEVICE =
  'maintainableitems/%id/sensors/types/PROVIDER_DEVICE/devices';
export const PACKS_METADATA = 'packs/metadata';
export const SUB_UNIT_PACKS = 'subunits/%id/packs';
export const SUB_UNIT_ACTIVE_PACKS = 'subunits/%id/packs/active';
export const PACK_SENSORS = 'packs/%id/sensors';
export const PACK_VALUES = 'packs/%id/values';
export const SENSORS_PROPERTIES = 'sensors/types/%type/properties';
export const SENSORS_REPLACE_DEVICE = 'sensors/%sensorId/replace';
export const SENSORS_UPDATE_SENSOR_ORIENTATION = 'sensors/%id/orientation';
export const SENSOR_POST_THRESHOLD =
  'sensors/%sensorId/properties/%propertyId/thresholds';
export const SENSOR_DEACTIVATE_THRESHOLD = `thresholds/%id/deactivate`;

/* Messaging Service API */
export const MESSAGING_USER_NOTIFICATIONS = 'users/%userId/notifications';
export const MESSAGING_MARK_NOTIFICATIONS_READ = 'notifications/read';

/* Status Monitoring live data (websocket) */
export const SENSOR_STATUS_MONITORING_DATA = 'values';
