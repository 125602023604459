import { DEFAULT_LANGUAGE, USER_LANGUAGE_MESSAGES } from 'utils/constants';
import { TranslationMessages } from './types';
import { getTranslations as getTranslationsService } from 'services/translationService';
import en from 'locales/en.json';

export const getTranslations = (): TranslationMessages => {
  const fromLocalStorage = localStorage.getItem(USER_LANGUAGE_MESSAGES);

  return fromLocalStorage
    ? JSON.parse(fromLocalStorage)
    : { lang: DEFAULT_LANGUAGE, messages: en };
};

export const fetchTranslations = async (language: string) => {
  const { data } = await getTranslationsService(language);
  const translations = {
    lang: language,
    updatedAt: Date.now(),
    messages: data,
  } as TranslationMessages;

  localStorage.setItem(USER_LANGUAGE_MESSAGES, JSON.stringify(translations));

  return translations;
};
