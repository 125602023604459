import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { HomeIcon } from '@eriksdigital/atomic-ui/components/Icons';
import styled, { css } from 'styled-components';

import { ReactComponent as DocumentsIcon } from 'assets/icons/documents.svg';
import { ReactComponent as WorkOrdersIcon } from 'assets/icons/work_orders.svg';
import { translationString } from 'locales/translation';
import { useInjectReducer } from 'utils/redux-injectors';
import routeHelpers from 'utils/route-helpers';
import { matchMedia, mediaQuery } from 'utils/mediaQuery';
import MenuAssets from './components/MenuAssets';
import MenuEquipment from './components/MenuEquipment';
import MenuItem from './components/MenuItem';
import MenuSettings from './components/MenuSettings';
import { selectMenuIsOpen, selectSubMenuIsOpen } from './selectors';
import { actions, reducer, sliceKey } from './slice';
import CreateEquipmentTypeModal from './components/CreateEquipmentTypeModal';
import {
  selectSelectedCustomer,
  selectSelectedLocation,
} from '../CustomerLocation/selectors';

function SideMenu() {
  useInjectReducer({ key: sliceKey, reducer: reducer });

  const isMenuOpen = useSelector(selectMenuIsOpen);
  const isSubMenuOpen = useSelector(selectSubMenuIsOpen());
  const customerId = useSelector(selectSelectedCustomer);
  const locationId = useSelector(selectSelectedLocation);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.close());
  }, [location, dispatch]);

  useEffect(() => {
    if (matchMedia.phoneLarge.matches) {
      return;
    }

    if (isMenuOpen) {
      document.documentElement.style.setProperty('overflow', 'hidden');
      document.body.style.setProperty('overflow', 'hidden');
    } else {
      document.body.style.removeProperty('overflow');
      document.documentElement.style.removeProperty('overflow');
    }
  }, [isMenuOpen]);

  const ABTestCustomers = [
    '576a83be-16f7-43e4-929c-6769501267aa', // BritishGypsum Dev
    'd4bcde24-bc04-494a-8fe4-a0f386f7566c', // ERIKS UK Demo Acc/Prod
    '85dc4f03-7cf3-44ef-a5a5-3852d912cd8e', // ED QA Automation
    '3d06f50a-4c4c-4ce1-a482-4db21c0452f5', // ERIKS UK Demo Dev
    'c03b1cb3-4afb-4fca-9efd-597febb5b618',
    'b3be2c9b-0caf-4b93-8b6c-c3ee31aa85d0', // Friesland Campina (Prod)

    // UK
    '46086bd4-931c-42c5-bee4-f94b65e1188c',
    'c4171563-4b18-43e7-ac69-83482b6ac040',
    '562f36e9-5125-4af2-bb3a-bf64b9fa48cf',
    'e2f92942-4d49-40ea-bc04-f8f958afa00f',
    // DE
    '3fe2d14f-b950-46c5-9f67-3831be31c1f9', // ADM WILD Europe GmbH & Co. KG - Werk Berlin
    '42f40ff6-d254-4f5b-ae61-041bac06f351', // Airbus Aerostructures GmbH
    'ff92b29b-6d65-4078-9b7b-3386b241b76a', // Daimler Truck, Wörth
    'debe0b36-94c5-452a-90be-d8c4f566bbcc', // Daimler Truck AG - Stuttgart
    'ff92b29b-6d65-4078-9b7b-3386b241b76a', // Daimler Truck, Wörth
    '96fff710-41eb-4b71-8943-024061416189', // Dr. Kurt Wolff GmbH & Co. KG
    'f7e14219-4f1d-437b-bdf2-5545ced9c115', // Henkel AG & Co.KGaA
    '512ea1b2-ac52-47bc-a15c-96a6a8abab24', // Linde GmbH
    'c4d46ee7-d8a1-46ca-ac68-ed3c1bfa76f8', // Mercedes-Benz AG
    'e9919e85-0fd4-4fbe-ae5d-ff092dbd1393', // Mercedes-Benz AG VAN
    '32241235-7a30-4f30-991c-8f1b73ff4a6a', // PPG Wörwag Coatings GmbH & Co. KG
    // FR
    '99623dd3-7b37-4093-a3bb-95a61e8b97bc', //ERIKS France
    '8afddb4c-e11f-493a-8189-d95d89b5e46d', //FIRMENICH - Grasse
    '2ce1672a-281f-4727-bb3e-1bfbe3e39b5c', //LAMESTA FINE
    '42330daf-60ee-45f9-8e17-16e20ca752fe', //LANXESS - Epierre
    '5046d9aa-3b95-4178-97c3-1af8ccd29816', //SERGE FERRARI
    '1992e35e-0f9d-4177-afd0-dd6f7cb1dcae', // HEXCEL fibers - Roussillon
    '01505359-b6ce-4cac-a4f0-4c56179f687b', // SEQENS - Roussillon
    // ACC
    'ff9a6f0d-e338-4944-9311-0bc35fff1c7d', // Centrient (VH DSM Sinochem)
    //SRS prod
    '1501341b-1971-4730-9c35-c98dad74451b', 'afba5d6e-ee14-49f0-9584-773b32d41c2e', '2f7ff52f-c1b1-4c41-bebe-ec4a8e7eb5ec', 'c03404be-c585-49b4-8e68-92aebc0ea97f', 
    '800814d9-ae2f-41c6-9753-e5c6d97949ef', '3aa4427c-7144-4df6-9b75-69b239254b78', 'ebf49d11-1faf-4422-b370-4e539ff45b85', 'e6468647-b17e-4a91-adc0-babeb9ab222b', 
    'efc2ebb3-edc8-4d5a-be6d-875a314c59b3', '32c48432-30fd-4d1c-b51a-97f064c0e537', '109b98e4-8b58-4ff8-98b5-1cbe84fb6692', '6ba2bbea-24b5-4052-a49f-da2be1d73a57', 
    '49cd89f2-3b2c-4e1d-833f-d46a3e6eaa0c', '8684296f-0dee-4327-8a19-fc9672c0d7a2', '684046bb-51af-41fd-a168-1d1c1d141eca', '888e44cc-bb08-4192-9f81-5727e1fccfbc', 
    '78810572-aef4-45fa-bedb-8c2dca2be463', 'b6c49055-7fe9-4d9e-9b73-8303d3a1797b', '03e4dcdb-231d-45d9-9c97-483f24dc70e7', 'e8ce6547-e01b-4da6-b075-341b058f4218', 
    'dbe4b209-332f-4801-965e-ff2d0976bf74', '25246402-46df-4279-abf5-7de02d18bb8a', '3d34f87c-eb0a-42c5-a8a1-69fd8acf7068', '2c6a0eb9-d2b8-4be2-9d43-c95ad2b998f9', 
    'ddb5c0e0-9f18-4f83-b387-664414985d1e', 'a5949dd5-c67c-4530-a395-980288d58ae4', 'bd42a336-9895-4051-a205-f66408b3b4a9', '4ae082d0-9ab2-43ee-a190-8cd2d4cb2253', 
    '9132fa4a-370d-47c4-8811-3424c413ac9b', 'b60f3577-78f2-42cd-bf32-809dfa83bd6e', '79f31930-438b-4984-84e5-039e613a459f', '9ff861a2-f11b-4ff3-aa35-bd5abd55e489', 
    'f7a8c60a-0ec3-4272-850c-7f4a49902cef', 'b8383de4-6e54-4886-91bd-dea487ceb626', 'edb3d3ee-330c-4de4-9242-e0475fe9af43', '7be70070-9e0e-45ba-a2ec-6477f177b59f', 
    '0e7e56dc-9395-4cb9-a23b-967910dbec2a', '30ba65ea-f99f-4e3a-9000-55b293360c25', '5bb982f5-9d99-44ab-a5ab-571b8b644223', 'c96a788d-4724-4541-95a3-365c18de7c99', 
    '2de8888a-6d24-4bf6-afd2-e5cccaa3c274', 'babb4861-64a0-43eb-b83a-fb068e82d463', '002bc82d-8a98-4991-89a5-3b4308033f05', 'b01f5ec9-e499-4150-929a-61633de72e19', 
    '5ce692e9-b50f-4e8e-a5d2-8b304009d446', '638735a9-a086-4e0d-bbff-b6f2437f1d50', '2507f947-bdd5-4e30-b1fd-55fb0cf58958', 'd5ef3747-138f-4d8b-9f75-1702850d85dc', 
    '0e4673fd-0c03-4b74-a3a8-0894a85c41cd', '2ba1177a-1e7e-4428-bdca-cc5fe5ed120e', 'e22e302d-7fa6-4608-a796-aaed8799d204', 'e1044a23-791e-4bf6-84b8-19a5497d75f1', 
    '82169eb8-17e7-4afa-be95-e898515a24b6', '17296848-b79b-4537-9574-53427663a24f', 'bad8349e-03b1-44a7-a397-fe016a87a435', '384c5ea1-2839-4ef4-855d-46f7acfdad22', 
    '5652730c-3228-4982-869f-021b41ee3ec5', '6c128065-e134-4255-bfd9-596af1ee84a1', '0d39bcdb-dcb2-491b-a12f-0d40e01ca903', 'e355154c-0070-43d2-8a0c-67da1e5fe409', 
    'ef36f1d0-ef64-4c83-b1cd-b872290317dc', '456d2f73-77fe-47d1-9209-0fc5711eb574', '0d48e3cb-6246-480a-8755-3e25eb8548e1', '0a8e8a73-47f7-4181-aa1d-ab49119c4f55', 
    '78ec6d3d-be50-4099-ae01-3b3bd2014fe3', '122e6195-b3bc-4b01-80fc-60b813133c81', '8c1b7576-d093-4de9-8e16-fbe4cc7a8b31', '416ebc48-10e2-42d7-b60d-7d11a5f0b1d3', 
    '81cf5a1b-7962-4359-a623-0a99aace83bd', '0c7f7315-4fe0-42e3-8da7-b674db2c4076', 'dc46d664-d049-4231-9f29-55bb321d63a1', '76d1b929-9978-4ed9-aa5a-ca5e331bccd8', 
    '87885be1-3f15-4e5c-bcb5-0c6a0439ce4d', '9cad80e2-8404-4776-8c2b-eba5ff7d18bc', '0153a48a-b8c1-46b3-a764-96e32826c7f8', '4782ca89-26fa-4d42-9f7c-badee9e9b4fa', 
    '058df0bc-40d4-4b08-9699-dca40f0f5cbb', '8ffa45c4-afa3-480f-8372-e17bc2f93c74', '7ac01786-511c-42d3-834f-2e522d87bb8a', '639d7d76-e21f-412b-ac18-e9f63ab3f99e', 
    'fc688124-b993-4c97-b614-694b1ae93447', '0aa9bdc6-f057-479a-af96-4111fe4dff94', 'c783f878-8e0a-47cf-81f5-b3c8c3e1821a', 'd4bcde24-bc04-494a-8fe4-a0f386f7566c', 
    '34d8d18e-fd0d-450e-92de-ea459feadbeb', 'a371c9b4-96c2-4235-a15a-30980e2a8996', '8106eb76-bff9-4543-8f68-47c3897deb94', '96640b26-bc0d-49b7-bf99-35166cbf9ffb', 
    'b91c5b03-dfdc-4bb4-9106-1541aca46829', 'f43be229-49f3-440d-80b9-8fc456758557', '0730e0ac-6da5-477c-a833-a1f8b94a1d57', '86ab2cfa-f0fe-4281-8b86-c042affc7e6d', 
    'fcecfe6f-4699-4d0f-80e3-ac2961317a6f', 'c6a5c7f5-c01c-4f12-9951-99d1b029eeb8', 'f6bfba46-e294-4e49-998c-7f782e542f95', '374fc381-23c5-4217-84e3-6e0e40428585', 
    '3d6433dd-cab8-46b9-86ed-2402fb7ca41a', 'd7de4fbb-ab59-4fd8-b2a7-689b8d4c8ca1', '3f20a8cc-7b9d-49e3-853c-f988667d605b', 'bebbe6e6-2a5c-47cb-8c0c-f1ca74a53048', 
    '71e03e77-57c1-4324-a7d8-a4d60c07ef45', '757a94af-e72b-4680-a859-41fb2f1ccdd1', 'fd3fd325-a2d1-4a24-a1a7-d0354642ba0c', '850c98fc-219c-4bde-aa3d-595763a6596e', 
    'ad3d5649-61b7-4bfc-b8ea-53274edd079e', '0d830c1b-bc46-4fce-a38b-372c456fe099', '3c722ee2-1750-40d1-9071-132210899655', 'f0379307-f659-416f-bc91-dc79a9511403', 
    '5dc333cc-8a87-4ea0-8947-24bef108f496', '7c058def-e093-4d55-a555-13b53bdae24e', '90748eab-265d-4784-9218-d499b647aced', '8ad2ae17-9c4b-4d6e-965c-d211caef8caa', 
    '573c76a4-41e0-4771-a89e-075f3695b16a', '5098dd75-df39-4704-9ebf-9a80f0c3bb1e', '5de5be8a-cb2f-49eb-9836-575670f30263', '34552f10-a4ac-45e0-bdae-fe04aafe806a', 
    '38133be6-6199-42ac-8ed5-86b2fdf0fdd8', '59b6fc83-4fc7-4f69-9d71-df8054aa4369', '72d4fe9c-df14-4612-9c94-51f3c3ab92d1', '996909b9-792a-4cc8-97e7-aad333ce31b1', 
    '85d746af-e59f-43a8-ab4d-0ebe6b123c1d', '1e49d219-9c80-4d86-a0b4-15ac15c6c637', '0baa056b-3c26-414d-8fde-6c74356c0e11', '253eadfa-2266-48c6-8b87-ed7f895f6982', 
    '69e2ef55-3185-4df8-bd33-7c8aef15d8e4', 'c7d6f7e9-b7e2-4700-b64b-3882d401d539', 'c711bf6e-0538-40b3-8e9c-f7110d7f10e3', '9a81e050-cff1-477c-a9d3-52ebdcb0defc', 
    'c0b9b0d2-9975-4ab8-8cd0-07fae5aa789a', '056c8083-1ccf-4f01-8a09-65d8397a4c62', '519e4cb3-3bee-4ef6-83aa-5f0a39b7d2d0', '7ec954d2-471f-4068-b378-6b7db5549fb9', 
    '02ab2b78-19fb-4faa-b7d4-2edcedceef71', 'd36a8961-5592-4a11-bd87-7474f16c998b', 'cd0eef2d-3b58-4e9e-89c5-1546811e5b87', '14cc32b7-c715-4780-ad41-d4e335f88807', 
    '2916dd07-66e1-410a-a3ad-aaa5abfc9160', '2cd54558-690a-4c27-a2fe-10728a2f8807', '3e17ddb1-1533-4ad4-9af6-e30ada4d4bcc', 'a4cf2d76-85d9-4da8-90c9-98739ddd09a0', 
    '7fe4f975-5326-4f5c-a527-a08725f28d09', '98b5766b-813b-46cf-8a67-84366e6451de', '3edbb71b-a4ff-4409-b748-6b9cbe39960b', 'b3be2c9b-0caf-4b93-8b6c-c3ee31aa85d0', 
    '47f293a7-ae7d-49f2-b754-1f9abeb51869', '5912ba1f-3cc3-40f8-9261-899e1a8d7b90', '73c1f01c-c784-486c-9bb0-c7fc7a17bfa5', '682f37dd-0cfb-4f9b-8da2-56baeb3fe99e', 
    '2c2d025b-c66f-4e1f-b9f7-8488d8e27a42', '251c75df-c55f-48ac-8252-afd15bed9f97', '8d45f013-ec33-4096-885a-193f9007eefe', 'fb376686-48c3-47a5-9266-af95e97f83cd', 
    'e9dd94c9-52a0-4139-b531-7bd3050fbee2', '57a31c75-5748-4d03-9f7b-ca6134e8e05d', '5e8eb72a-8efc-4ca7-9fd1-4637d66ffcc7', '325983ef-f6c5-4670-9feb-3f79cfc147a4', 
    '53bbda5f-cb94-4c3c-a0a1-747a32d3d2e7', '1f6dc502-b286-49be-aae1-480455a31fa4', 'fde8f59f-28c0-471e-8237-6f044c5a314e', '6410b962-3916-410f-860b-d58a5af82ef8', 
    '8e043cb7-7623-438f-b40d-5f2b9e48900d', 'c86c06b5-7256-44f2-8589-45337d3db1cb', '3132123d-25ad-471f-bb3f-b044633c8b17', '061f65d9-e742-4fd1-a253-67c9fb9fb366', 
    '78fd728d-df40-48b5-971a-70a33e5dad59', 'cd5e2ebc-97bb-4747-8e0b-9b25c506b652', '08b44161-5154-4309-a2ba-085d9bba67ea', 'b6d82919-ae30-4b19-95a6-6c4dcac81521', 
    '6981ad7c-7970-4237-a779-0564062d0a85', '34296426-a290-46b0-896d-5b82ce6e202c', '0729e83a-642f-4888-9f06-f881cd6147fc', '48e25232-91fb-433a-9571-13e43ea3ceb4', 
    'f4111966-49e4-4d0a-a313-8fd67b868e36', 'fe94b40d-2372-489b-a25f-7ba0bbe3b54a', 'a05ebd0f-d694-46bd-8669-845577aab3f2', '836574c8-4c4c-4105-b262-04154359a591', 
    '061146e8-385f-4586-8983-fbd33c8f01a8', 'af29b3f1-beec-4dfa-a0b0-56069b9b1c60', '039304c5-0bd2-4542-86bd-935a3ed3a27d', '008fdc5b-8782-4b26-b0a2-2587f4cd4e7d', 
    'e6f69afa-7d22-4919-96e0-e81846de638f', '2b607710-eca7-402f-9cbd-343f8bfd647b', 'ad04c04a-feaf-44e6-b682-96a66181210e', 'b440a80f-2495-40cf-9909-f585e8ab656d', 
    'd4bcde24-bc04-494a-8fe4-a0f386f7566c', '3e08398f-dc27-479e-8527-f5ed100f6a36', '24b2ffcf-7d5c-437c-b02c-749ffa2c9100', '0c8a4223-1790-4c1e-8a2d-c0c0d02796e3', 
    'f0eb2563-b729-4b9c-b713-140eebe08752', '888d2c4a-8377-40fe-94ae-81dd30dbe83b', 'cb774419-5a86-46f3-9fe3-2e6b29bc6a19', '42b38edf-3df9-49c2-92c7-c4002fe5af88', 
    '83370f3d-400d-4c03-9c45-0d73a99fefe4', '7e862249-3b9a-4170-b584-081cdc1a6cc8', '17583c36-2112-4e3f-8880-36783917ab95', '04c8166a-c9b0-42e6-a08e-4d779041a119', 
    '6784135b-4381-4266-9df1-9187cbfa6e05', 'b2ead283-c39d-44c9-9d5e-3bc73199b60f', 'f22fc0cf-572a-45ae-84b2-854b9989a35e', 'dd3000ba-348f-4ed7-a08a-2516b1382bf3', 
    'c1bccb71-10de-44fd-97ed-6bf381d2b73a', 'a807b3c4-ca58-472c-916c-83039c49bd66', 'fc3f3ad1-da7a-409d-beaf-84107667761c', '486e41d5-faf3-458b-8f3c-fbda220c8678', 
    '5e23c14d-fc91-4493-b387-a146f6fbf43d', 'fbf3988b-58d8-4c00-a566-75e521e052df', 'b34fdab7-428b-4d19-9822-caee2f94ab3c', '336fdb82-7e57-4791-a6c2-b5d4d03d4278', 
    '0ab5e95e-68fd-42e2-9cd1-b4bc8e8b6769', 'b561ae84-6a2a-4445-8850-cc531ddf7d5c', '57e63ad3-c7ea-421d-86f4-a9a3f788ae6b', 'e9d4d1f8-2410-4337-80ad-cbbc0627e518', 
    '9ed89703-ed9a-4465-b85e-8a90aa956279', '4fe73632-fcac-4fc2-b1c3-b5d729160197', 'efea4d9f-ce86-45de-90f8-590f4b5b9a7f', 'f066f5fb-a815-4bb4-b11b-f926deff8bd8', 
    '57720cfb-df1c-44a3-a7d2-ffc26e799cd9', '18fb939a-4c09-433e-ba17-072f74889247', '6c6f093f-0ca7-4d25-97ea-495aebf986db', '4e375dd9-3a1e-4524-9055-de62c33fef5a', 
    'e04dd842-1f83-4073-a9be-f507be584f41', 'fc577ddf-4ed6-405b-ab34-ba4ab25d4ca8', '6a96b683-64cd-4220-81bc-11b1aa813225', '71afcb67-08ef-4330-a537-09c42e01044a', 
    '0716c88c-45c2-4a46-835e-f1bf879140e1', 'e505344a-f692-4167-ae34-0a10319d842a', 'b549cd8c-57e3-4516-9439-adaf6ecfc11e', '48a2c615-b295-47e6-a095-3d92f1a591fb', 
    '319ef69e-b4e9-481e-ae5c-c89786a9240c', '85dd4f34-9307-4c07-8fec-41201978410b', '08bd771c-04f1-40fc-99f5-d4e0a8f5eb9f', '37e9645f-b808-4658-9112-e947405c2ba3', 
    '988b5335-a5b0-4fde-bfaa-f72d3d769bea', '05046e97-a22d-4795-ab64-3228ca22b914', '370e8af1-5cd0-4138-8a9a-ea40f9a1cba7', '9ff79e9f-2ba2-49c8-881a-7043d97b8c3e', 
    '45d501e1-2d1c-42c7-b70f-60d2324dcdc1', 'c22497c3-3cc8-4405-bbf2-c6fc8a4bcb9d', '6f1cf5ef-4a1a-4b1f-96b8-cdaf88040b93', 'ddd0f793-086c-448c-95d8-142f301b00b1', 
    '5d5b9e60-c4fb-4218-938d-a659dbaa72d9', 'e195b2e2-4c29-491d-b5ce-e22f0fc74d16', 'cd3e676e-1be8-41a5-89e4-e339f4c8665b', '4b96c4f6-243c-4c83-9bdd-d1ed028aab97', 
    'cd335375-88b9-43f2-90f0-e5b9239cbe13', 'fdd34b13-389b-4ff7-88ec-30ff587b2759', '4a2b0086-6838-4c4d-8b89-529ad245fc9f', '27d6a07d-150a-4054-815f-4e9369e9b999', 
    '5892bb34-87a3-4c26-a7da-8102664e13ee', 'f151d755-f2a9-474f-b51b-033ca32645f9', '7abcf0aa-51f9-461a-8b18-408968b9b956', '10416fc0-81e1-439f-98fa-0ace9c3191e3', 
    '9228ae34-8bbb-4125-a205-801f51a43582', 'b875c740-d3d1-41b3-b506-25a6e88b4561', '7684ea30-e0f1-48a9-80e1-ac042363f400', '0c5cbfc5-27ab-48ee-9851-c80f79cac575', 
    'a117381b-4255-4e16-b3d5-309107452a4a', '26a60ea0-57de-4308-bd9d-336bba862013', '3a973a03-06fd-4855-9bfe-17b6e33d8980', '384efb8f-1178-4b46-a2eb-44b714f66f7c', 
    'fc03c61a-780e-42e2-9ca4-7d17c04a873a', 'e999b4f5-65a6-44ba-942b-e48800df3807', 'da30b149-bacb-4795-a2d4-6f5292b173cb', '62d02d5f-d8e3-4e82-b990-22ac5c09f2f5', 
    '700af322-3711-45e3-9966-faa16a16fdb3', '06a9ea46-9100-4177-908e-d2ee7fba4ff8', '56ab64ef-f390-4f02-8666-ef1b0d9bf079', '03563271-2448-4389-9171-fe44843095be', 
    '8e029053-a523-4ef2-9fb6-a5303cf8181e',
    '8214e115-2eb7-4fb1-ba84-7c4d58f0fd2d', '947c41c1-a2ac-44fe-8847-f378f2f9134a', '16e9d1e4-aeaf-48be-8a18-b36d4a798791', 'd5d6cefc-98b2-4866-b837-800b3178b14d', 
    'd4500ce1-8fca-4295-9a17-d9c285ee5b1c', '5fa112bf-acd7-4712-9c65-608c3b5427f1', '85ba31d7-b8aa-42ce-b712-fed98855d453', '0d0ae7c4-dead-4a78-ae86-f8c27e93c636', 
    'bcdf6dd3-4472-4e01-b570-14ad0442b319', 'd4500ce1-8fca-4295-9a17-d9c285ee5b1c', '0467c07b-b9fb-4e7b-ae81-2b7470f1a3fe', 'ef2a720e-0e19-484b-99ec-1d9aae9ae48e', 
    'f02afe77-4a49-4428-b6b7-a4577c5443cb', 'b253249b-3850-43a3-bf04-93231843e9b3', '164a223b-d491-4637-b975-b7e3ba7fa692', '8a051581-3507-415b-b33b-e58a33c28750', 
    '4316cd9b-3dd7-441a-ac09-1e742ae00b24', 'ad00ba50-ee82-462c-bf9e-bb6d8169dd11', '84b1d106-68f9-4354-8754-2a9e62e57b57', '9fd34abb-18a1-45e8-98c1-4415ac0c7f7e', 
    '3335bea0-8d47-4a8c-a6e8-1b79654cf5f1', '59a61f70-9b4d-441f-9cc8-1a71b8c50186', '62f52ced-7db1-4658-872b-2188a1a2f076', 'f8e195fe-53ab-40dd-9597-e2a9bf58eba2', 
    '8c5dc9e5-8857-4646-961c-82dc8b67be45', 'e89c8e44-0470-4dc5-b007-fa5797f21f40', '53d94523-4f1f-4247-8fdb-c66855ee6168', 'd4500ce1-8fca-4295-9a17-d9c285ee5b1c', 
    '2b407e6c-695d-49fa-99d9-b0e3397eae9b', 'dcdac897-8015-48ec-ac19-53cf4facbfe6', '3923b662-1b1d-44cf-b19b-5d96880b1980', '543f30a9-61e4-4efe-8b2a-e573d8a0593f', 
    '3c2df553-dca1-4228-8cfd-7ab9d4ce2757', '08cfa074-f4ba-4640-a92a-7efed520110b', 'c91fbc02-769e-4d21-bb00-0e242c63296e', '505bf32c-ebe5-4c10-8ea2-44f0e0c49060', 
    'd9be46ec-686e-4d49-892a-0526d7c1e367', 'ed5f6a80-4fce-4bd1-8d49-5e998e75a37e', 'd75b7a4f-1ba8-4e2f-b258-127c73c2d750', 'bd3a79c8-f392-4a2b-b49c-1979d165b559', 
    'a997f48d-e3dc-4b71-b1fa-e3a31c3373e4', '4c01cd76-8c76-4b1b-a4f6-f0e2cd8f48b9', 'd4500ce1-8fca-4295-9a17-d9c285ee5b1c', '17097e9e-9a8f-4593-8379-4a653e39ed7c', 
    '7e9b497d-5b21-43fc-aa6d-9d278dbedffb', '33cd02c6-976c-495b-8a3d-3249319ab07e', '5a0f925f-ff90-45df-8723-9851cd5a9e29', '5d83644b-0cda-427d-b55d-05888a26db6c', 
    '49540e93-c81a-4c6d-89f3-a61196873d6b', 'd481639f-6d50-4346-96db-51b310d0afbe', '5ee7392c-fc66-4315-9193-800686a5a6ce', 'a0d3261f-3635-4d8a-91d1-b558aac027cb', 
    'f7c918d7-8bc6-4847-81a9-befc776072cf', '372e790f-7874-4b49-8e26-9f686234fadd', 'bc4debe5-7144-470e-9ad2-0abb0e011eb6', '9360541e-437d-4b6f-baf8-466b26964ec7', 
    '98f1e005-c892-46d9-88b4-3608af66294a', '9b5146cd-ab6f-4e4b-9840-92c19049de42', '99a74125-bf43-48cf-97fb-061c7e322ed6', '9ceebbe6-4d23-48a4-94ea-37d9a8fea2e2', 
    'd556087a-5c33-44d0-8704-4f5629f50938', '236b62f8-204b-423e-876e-4a3f76b53d24', '591d5233-aaa1-4c7f-8c3e-360a2d0b8ead', 'ba3e43cc-a32b-4684-acb2-d53a94426075', 
    'e5dac454-11e5-44ee-8973-2ca8818616b7', 'b49ef556-a953-412c-855e-2c8b61d152f1', '9f0720bf-e970-4caa-a707-8ec66becd2a7', 'c79a0ee2-8eb0-4957-8c64-b984d98ac785', 
    '2be9d369-b6f9-4745-a27f-a651d50feb11', '79c5ec18-7362-4f02-84f8-595e6d3e9396', 'c05e3121-c2e9-415a-9724-2acafacc03b0', '4ad5d5ad-123c-4f4d-bbf5-79e6161bdccf',
    '0410f06c-4787-4101-8cda-34672addf42e','42330daf-60ee-45f9-8e17-16e20ca752fe', '232b6335-1e65-474b-b530-c3ce852645c0', '5d8bb860-1813-412f-ae22-496510c0436b',
    '7f7052ff-bf0f-4687-a92f-079c52884036','3ece79e5-89dd-4ff0-b817-901c6b35c4fe','63cc39f4-5f07-49c7-882d-d731c75f0e15','94012b1c-43d0-42e6-91b5-1368e250daaa'
  ];

  return (
    <SideMenuContainer isMenuOpen={isMenuOpen} isSubMenuOpen={isSubMenuOpen}>
      <Menu>
        {locationId && ABTestCustomers.includes(customerId) && (
          <MenuItem
            to={routeHelpers.buildMainDashboardRoute(customerId, locationId)}
            icon={HomeIcon}
            label={translationString('Dashboard')}
          />
        )}

        <MenuAssets />
        {ABTestCustomers.includes(customerId) && <MenuEquipment />}
        <MenuItem
          to={routeHelpers.buildDashboardTasksRoute(customerId, locationId)}
          icon={WorkOrdersIcon}
          label={translationString('Tasks')}
        />
        {/* <MenuItem to="/" icon={QuoteIcon} label={translationString('Quotes')} /> */}
        <MenuItem
          to={routeHelpers.buildDashboardAttachmentsRoute(
            customerId,
            locationId,
          )}
          icon={DocumentsIcon}
          label={translationString('Attachments.Attachments')}
        />
        <MenuSettings />
      </Menu>
      <CreateEquipmentTypeModal />
    </SideMenuContainer>
  );
}

export default withRouter(SideMenu);

const SideMenuContainer = styled.nav<{
  isMenuOpen: boolean;
  isSubMenuOpen: boolean;
}>`
  position: fixed;
  top: 40px;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-width: 0;
  background-color: ${({ theme }) => theme.colors.default.blue1};
  z-index: 2;
  transition: max-width 300ms;

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      max-width: 100vw;
    `}

  ${({ isSubMenuOpen }) =>
    isSubMenuOpen &&
    css`
      max-width: 0;
    `}

  ${mediaQuery.phoneLarge} {
    top: 80px;
    width: 96px;
    max-width: unset;
  }
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow: hidden;
`;
