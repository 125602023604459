import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { DataTableColumn } from '../types';
import Select from 'app/components/Select';
import useSelection from '../useSelection';
import { translationString } from 'locales/translation';

type Props = {
  column: DataTableColumn;
  selected: string[];
  onSubmit: (selected: string[]) => void;
};

function EnumFilter({ column, selected, onSubmit }: Props) {
  const { toggle, selected: staged, clear, select } = useSelection();
  const options =
    column?.enumValues?.map(({ label: content, id }) => ({
      id,
      content: translationString(
        `Attributes.${column.id}.enum.${id}`,
        undefined,
        false,
        content,
      ),
    })) || [];

  const handleClose = () => {
    clear();
    select(selected);
  };

  useEffect(() => {
    clear();
    select(selected);
  }, [selected, select, clear]);

  return (
    <Wrapper>
      <Select
        name={column.id}
        options={options}
        onSelect={toggle}
        selected={staged}
        onClose={handleClose}
        onSubmit={onSubmit}
      />
    </Wrapper>
  );
}

export default EnumFilter;

export const Wrapper = styled.div`
  width: 200px;
`;
