/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-polyfill';
import 'url-search-params-polyfill';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as serviceWorker from 'serviceWorker';
import { defaultTheme } from '@eriksdigital/atomic-ui/styles';
import TagManager from 'react-gtm-module';
import SimpleReactLightbox from 'simple-react-lightbox';

import 'sanitize.css/sanitize.css';
import { GlobalStyle } from 'styles/global-styles';

// Import root app
import { App } from 'app';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from 'store/configureStore';

import AuthProvider from 'app/providers/AuthProvider';
import { ReportProvider } from './app/providers/ReportProvider';
import { TranslationProvider } from 'app/providers/TranslationProvider';

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

interface Props {
  Component: typeof App;
}

const ConnectedApp = ({ Component }: Props) => (
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <ThemeProvider theme={defaultTheme}>
          <TranslationProvider>
            <AuthProvider>
              <ReportProvider />
              <SimpleReactLightbox>
                <Component />
              </SimpleReactLightbox>
            </AuthProvider>
            <GlobalStyle />
          </TranslationProvider>
        </ThemeProvider>
      </React.StrictMode>
    </HelmetProvider>
  </Provider>
);

const tagManagerArgs = {
  gtmId: 'GTM-N7PH6C4',
};

TagManager.initialize(tagManagerArgs);

const render = (Component: typeof App) => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./app', './locales/intl'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    const App = require('./app').App;
    render(App);
  });
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
