/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, useLocation } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { GlobalStyle } from 'styles/global-styles';
import { history } from 'store/configureStore';
import { translationString } from 'locales/translation';
import {
  DEFAULT_LANGUAGE,
  REMEMBER_REQUEST_PATH_KEY,
  ROUTES,
} from 'utils/constants';
import { useAuth, useUserDetails } from 'app/providers/AuthProvider';
import { TAM, FAM, CAM, USER } from 'app/providers/AuthProvider/roles';
import ErrorBoundary from 'app/components/ErrorBoundary';
import { PrivateRoute } from 'app/components/PrivateRoute';
import { NotFoundPage } from 'app/components/NotFoundPage/Loadable';
import { CookieStatement } from 'app/components/CookieStatement';
import { AppHeaderBar } from 'app/containers/AppHeaderBar';
import { AnnouncementsToast } from 'app/containers/AnnouncementsToast';
import { Notifications } from 'app/containers/Notifications';
import { HomePage } from 'app/containers/HomePage/Loadable';
import { AssetsDashboard } from 'app/containers/AssetsDashboard/Loadable';
import { Dashboard } from 'app/containers/Dashboard/Loadable';
import { AnalyticsPage } from 'app/containers/AnalyticsPage/Loadable';
import { AdminPage } from 'app/containers/AdminPage';
import { QrCode } from 'app/containers/QrCode';
import { Equipment } from 'app/containers/Equipment';
import { Logs } from 'app/containers/Equipment/components/Logs';
import { Tasks } from 'app/containers/Equipment/components/Tasks';
import { Attachments } from 'app/containers/Equipment/components/Attachments';
import { EquipmentsPage } from './containers/Equipments/Loadable';
import { EquipmentFormPage } from './containers/EquipmentForm/Loadable';
import { Tags } from './containers/Tags/Loadable';

import SideMenu from './containers/SideMenu';
import { mediaQuery } from 'utils/mediaQuery';
import { EquipmentDetail } from './containers/EquipmentDetail';
import { Sandbox } from './containers/Sandbox';
import { TaskDetails } from './containers/TaskDetails';
import { AssetDashboard } from './containers/AssetDashboard/Loadable';
import { SpaceDashboard } from './containers/SpaceDashboard/Loadable';
import { useTranslationContext } from './providers/TranslationProvider/TranslationContext';

const CustomersForGuestAccess = [
  'd4bcde24-bc04-494a-8fe4-a0f386f7566c', // ERIKS UK Demo Acc/Prod
];

const { REACT_APP_SET_COOKIE_SCRIPT } = process.env;

export const AppRoutingRoot = () => {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAuth();
  const isAuthedUser = isAuthenticated();
  const allRoles: string[] = [TAM, FAM, CAM, USER];

  const pathnameArrray = pathname.split('/');
  const domain = pathnameArrray[1];
  const customerId = pathnameArrray[2];

  const [allowGuestAccess, setAllowGuestAccess] = useState<boolean>(true);

  useEffect(() => {
    if (domain !== 'qr' && !CustomersForGuestAccess.includes(customerId)) {
      setAllowGuestAccess(false);
    }
  }, [customerId, domain]);

  // Temporarily save the requested path for an unauthenticated user
  // so we can restore it after the user completes authentication.
  useEffect(() => {
    if (!isAuthedUser && pathname.length > 1) {
      sessionStorage.setItem(REMEMBER_REQUEST_PATH_KEY, pathname + search);
    }
  }, [isAuthedUser, pathname, search]);

  return (
    <Switch>
      <Route exact path="/" component={HomePage} />

      <Route path="/en/cookies-page" component={CookieStatement} />
      <Route path="/en/privacy" component={CookieStatement} />
      <Route exact path="/qr/:qrId" component={QrCode} />

      {/* TODO: remove these routes (lines 105-133), after removing old equipment page */}
      {allowGuestAccess ? (
        <Route exact path="/c/:customerId/mi/:miId" component={Equipment} />
      ) : (
        <PrivateRoute
          exact
          path="/c/:customerId/mi/:miId"
          component={Equipment}
          requiredRoles={allRoles}
        />
      )}

      <PrivateRoute
        exact
        path="/c/:customerId/dashboard/:locationId/asset/:assetId/sensors"
        component={AnalyticsPage}
        requiredRoles={allRoles}
      />

      <PrivateRoute
        exact
        path="/c/:customerId/mi/:miId/allLogs"
        component={Logs}
        requiredRoles={allRoles}
      />
      <PrivateRoute
        exact
        path="/c/:customerId/mi/:miId/allTasks"
        component={Tasks}
        requiredRoles={allRoles}
      />
      <PrivateRoute
        exact
        path="/c/:customerId/mi/:miId/allAttachments"
        component={Attachments}
        requiredRoles={allRoles}
      />

      <PrivateRoute
        exact
        path={ROUTES.Equipments}
        component={EquipmentsPage}
        requiredRoles={allRoles}
      />
      <PrivateRoute
        path={ROUTES.EquipmentDetail}
        component={EquipmentDetail}
        requiredRoles={allRoles}
      />
      <PrivateRoute
        exact
        path={ROUTES.EquipmentForm}
        component={EquipmentFormPage}
        requiredRoles={allRoles}
      />

      <PrivateRoute
        exact
        path={ROUTES.Space}
        component={SpaceDashboard}
        requiredRoles={allRoles}
      />
      <PrivateRoute
        path={ROUTES.Asset}
        component={AssetDashboard}
        requiredRoles={allRoles}
      />
      <PrivateRoute
        exact
        path={ROUTES.Task}
        component={TaskDetails}
        requiredRoles={allRoles}
      />
      <PrivateRoute
        path={ROUTES.Dashboard}
        component={Dashboard}
        requiredRoles={allRoles}
      />

      <PrivateRoute
        path={ROUTES.Assets}
        component={AssetsDashboard}
        requiredRoles={allRoles}
      />
      <PrivateRoute
        path="/admin"
        component={AdminPage}
        requiredRoles={allRoles}
      />
      <PrivateRoute
        exact
        path={ROUTES.Tags}
        component={Tags}
        requiredRoles={allRoles}
      />
      <Route exact path="/sandbox" component={Sandbox} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export function App() {
  const user = useUserDetails();
  const lang = user?.language || DEFAULT_LANGUAGE;
  const { changeLanguage } = useTranslationContext();

  useEffect(() => {
    if (lang) {
      changeLanguage(lang);
    }
  }, [lang, changeLanguage]);

  useEffect(() => {
    if (REACT_APP_SET_COOKIE_SCRIPT !== 'true') return;

    // script for creating the modal for the cookie statement
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.id = 'Cookiebot';
    script.src =
      'https://consent.cookiebot.com/uc.js?cbid=a3c18b6f-3ed7-42e9-a226-92e883362ae5';
    script.setAttribute('data-culture', lang);

    document.body.appendChild(script);

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, [lang]);

  return (
    <ErrorBoundary>
      <ConnectedRouter history={history}>
        <Helmet
          titleTemplate={`${translationString('ApplicationTitle')} - %s`}
          defaultTitle={translationString('ApplicationTitle')}
        >
          <meta
            name="description"
            content="Smart Asset Management Portal Application"
          />
        </Helmet>
        <Notifications />

        <PageLayout>
          <AppHeaderBar />
          <SideMenu />
          <AnnouncementsToast />
          <AppRoutingRoot />
        </PageLayout>

        <GlobalStyle />
      </ConnectedRouter>
    </ErrorBoundary>
  );
}

export const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${mediaQuery.phoneLarge} {
    padding-left: 96px;
  }
`;
