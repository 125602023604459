import React from 'react';
import { RawIntlProvider } from 'react-intl';
import useTranslation from './useTranslation';
import { TranslationContext } from './TranslationContext';

type Props = {
  children: React.ReactNode;
};

export function TranslationProvider({ children }: Props) {
  const { changeLanguage, intl } = useTranslation();

  return (
    <TranslationContext.Provider value={{ changeLanguage }}>
      <RawIntlProvider value={intl}>{children}</RawIntlProvider>
    </TranslationContext.Provider>
  );
}
